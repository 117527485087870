.Breadcrumb-container {
    margin-top: 15px;
}

.breadcrumb-list {
    list-style: none;
    display: flex;
}

.breadcrumb-list__item {
    margin-right: 8px;
}

.breadcrumb_last span {
    font-weight: bold;
}