.error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f8f9fa;
    height: 100vh;
}

.error-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    max-width: 600px;
    padding: 40px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.error-heading {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #2d3748;
}

.error-text {
    font-size: 20px;
    margin-bottom: 30px;
    color: #4a5568;
}

.error-footer-text {
    text-align: center;
    font-size: 16px;
    margin-bottom: 30px;
    color: #4a5568;
}

.error-footer-text a {
    color: #3182CE;
    text-decoration: none;
}

.error-footer-text a:hover {
    text-decoration: underline;
}

.error-button {
    padding: 10px 20px;
    background-color: #3182CE; /* Цвет blue */
    color: white;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.error-button:hover {
    background-color: #2b6cb0;
}

.error-footer {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    color: #a0aec0;
    bottom: 0;
}

/* Адаптивные стили */
@media (max-width: 768px) {
    .error-heading {
        font-size: 2rem;
    }

    .error-text {
        font-size: 1rem;
    }

    .error-button {
        padding: 8px 16px;
        font-size: 0.9rem;
    }
}
