:root {
    --primary-color: #0C77C3;
    --secondary-color: #9747FF;
    --background-color: rgba(255, 255, 255, 0.4);
    --text-color: #242322;
    --font-family: Intervar, -apple-system, SFNSText-Regular, San Francisco, BlinkMacSystemFont, Segoe UI, PT Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  }

.Settings-container {
    font-family: var(--font-family);
    /*background-image: url('../images/background.png');*/
    backdrop-filter: blur(5px);
    background-color: var(--background-color);
    background-blend-mode: lighten;
    background-repeat: repeat;
    /*height: calc(100vh - 190px);*/
    min-height: calc(100vh - 30px);
    /*min-height: 100vh;*/
    display: flex;
    flex-direction: row;
    align-items: center;
    /*justify-content: space-evenly;*/

}

.Settings-content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    /*margin-bottom: 200px;*/
    /*max-width: 800px;*/
    /*width: 100%;*/
    /*padding: 2rem;*/
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 190px;
}

.Settings-content h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

.Settings-content .input-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 15px;
}

.Settings-content .input-group label {
    margin-bottom: 5px;
    font-size: 16px;
    color: #555;
}

.Settings-content .input-group input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.Settings-content .save-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #3182ce;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.Settings-content .save-button:hover {
    background-color: #2b6cb0;
}

.Settings-content-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 40vw;
    max-width: 60vw;
    margin: 20px;
}

.Settings-sidebar {
    width: 250px;
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-right: 1px solid #dee2e6;
    border-color: gray(200);
    display: flex;
    flex-direction: column;
    top: 0;
    margin-left: 10vw;
    margin-right: 20px;
    /*margin-bottom: 200px;*/
}

/*.modal-window {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    width: 60vw;*/
/*    margin: 0 auto;*/
/*    background-color: #fff;*/
/*    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);*/
/*    border-bottom-left-radius: 10px;*/
/*    border-bottom-right-radius: 10px;*/
/*    z-index: 100;*/
/*}*/

.chakra-modal__content {
    position: fixed !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
    left: 50% !important;
    justify-content: center !important;
    /*max-width: 400px !important;*/
    width: 40% !important;
    margin: 20px auto !important;
    background: white !important;
    border-radius: 8px !important;
    padding: 20px !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.chakra-modal__header {
    font-size: 1.5rem !important;
    font-weight: bold !important;
    margin-bottom: 1rem !important;
}

.chakra-modal__body {
    margin-bottom: 1rem !important;
}

.chakra-modal__footer {
    display: flex !important;
    justify-content: flex-end !important;
    gap: 1rem !important;
    margin-top: 1rem !important;
}

.chakra-modal__overlay {
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(8px);
}

.App-header-LogoutIcon {
    cursor: pointer;
    width: 40px;
    height: auto;
}

