:root {
    --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
}

.img {
    align-self: stretch;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    position: relative;
    min-width: 0;
    min-height: 0;
    background: url(images/cathev.png) no-repeat center;
    background-size: cover;
}

.App-footer {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    flex-flow: row wrap;
    flex-direction: column;
    text-align: center;
    font-size: 16px;
    width: 100vw;
    height: 160px;
    bottom: 0;
    padding: 0;
    background: #D9D9D9;
    /*border-bottom: 1px solid #D9D9D9;*/
    /*border-top: 1.5px solid #000000;*/
    align-content: space-around;
    margin-top: auto;
}

.Footer-body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


.Footer-links {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: space-around;
}

.Footer-links a {
    color: #4a68ff8e;
    text-decoration: none;
    margin: 1%;
    margin-top: 1.24471%;
}

.Footer-links a:hover {
    text-decoration: underline;
}

.Footer-links-left {
    margin-right: 70px;
    display: flex;
    width: 250px;
    height: 160px;
    flex-direction: column;
    align-content: flex-end;
    align-items: flex-end;
    justify-content: center;
    flex-wrap: wrap;
    padding: 5px;
}

.Footer-links-right {
    margin-left: 70px;
    display: flex;
    width: 250px;
    height: 160px;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    padding: 5px;
}

.Copyright-text, .About-project, .Site-KKRIT {
    padding-bottom: 5px;
    padding-top: 5px;
}

.TGBOT, .Support {
    padding-bottom: 5px;
    padding-top: 5px;
}

.QRBOT {
    position: absolute;
    display: flex;
    width: 100px;
    /*margin: -25px;*/
    margin-left: 800px;
    cursor: pointer;
    z-index: 8888;
}

.QRBOT.fullscreen {
    position: fixed;
    width: 300px !important;
    top: 460px;
    left: 0px;
    transform: translate(-50%, -50%) scale(0);
    z-index: 10000; /* Устанавливаем еще более высокий z-index для полноэкранного QR кода */
    opacity: 0;
}

.QRBOT.fullscreen.active {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
}

.CatheV {
    position: absolute;
    width: 100px;
    height: auto;
    align-items: center;
    display: flex;
    margin-right: 1vh;
    margin-left: 1vh;
}

.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: none;
}

.overlay.active {
    display: block;
}

.close-button {
    position: fixed;
    top: 10px;
    right: 10px;
    font-size: 24px;
    color: white;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 10001;
    display: none;
}

.close-button.active {
    display: block;
}

.prevent-scrolling {
    overflow: hidden;
}


@media (max-width: 768px) {
    .App-footer {
        flex-direction: column;
        height: auto;
        padding: 20px 10px;
        align-items: flex-start;
        align-content: flex-start;
    }

    .Footer-body {
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    .Footer-links {
        flex-direction: column;
        gap: 8px;
        margin: 8px 0;
    }

    .Footer-links-left,
    .Footer-links-right {
        width: 100%;
        align-items: flex-start;
        margin: 5px 0;
    }

    .copyright-text, .About-project, .Site-KKRIT,
    .TGBOT, .Support {
        font-size: 14px;
    }

    /*.QRBOT {*/
    /*    position: fixed;*/
    /*    width: 80px;*/
    /*    margin-left: 0;*/
    /*}*/

    /*.QRBOT.fullscreen {*/
    /*    top: 50%;*/
    /*    left: 50%;*/
    /*    width: 250px !important;*/
    /*}*/

    .CatheV {
        width: 80px;
    }

    .close-button {
        font-size: 20px;
    }

    .prevent-scrolling {
        overflow: hidden;
    }
}