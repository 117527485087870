.admin-nav-link {
    color: white;
    text-decoration: none;
    font-size: 16px;
    width: 100%;
    padding: 10px 0;
    display: flex;
    align-items: center;
    gap: 10px;
}

.admin-nav-link:hover {
    color: #3498db;
}

.admin-logo {
    height: 40px;
}

@media (max-width: 768px) {
    .admin-nav-link {
        font-size: 14px;
    }

    .admin-logo {
        height: 35px;
    }
}

/* Дополнительные стили для таблиц и графиков */
.chakra-table__container {
    overflow-x: auto;
}
