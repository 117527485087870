.equipment-card-container {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.equipment-card-content {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin-bottom: 2rem;
}

.equipment-image-container {
    flex: 1;
}

.equipment-large-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.equipment-summary {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.form-section {
    margin-bottom: 2rem;
}

.equipment-details-table {
    overflow-x: auto;
}

.purchase-document-link,
.warranty-card-link {
    color: #3182CE; /* Эквивалент color="blue.500" */
    text-decoration: underline;
}
