:root {
  --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
      "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
      "Source Han Sans CN", sans-serif;
}

.About-container {
  font-family: var(--default-font-family);
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  }
  
  .About-header {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .About-logo {
    height: 80px;
    margin-right: 20px;
  }
  
  .college-name {
    font-size: 24px;
  }
  
  .main-About-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .text-block {
    background-color: #ffffff;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    width: 30%;
    margin: 10px;
    flex: 0 1 auto;
  }
  
  h2 {
    color: #333;
    margin-top: 0;
  }
  
  p {
    color: #666;
    font-size: 16px;
  }

body {
  overflow-x: hidden;
  overflow-y: auto;
}