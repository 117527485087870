.main-menu-container {
    padding: 1.5rem;
    text-align: center;
}

.main-menu-title {
    font-size: 30px;
    margin-bottom: 2rem;
    font-weight: bold;
}

.menu-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    padding: 0 1rem;
}

.menu-item {
    position: relative;
    height: 160px;
    border-radius: 0.375rem;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.menu-item:hover {
    transform: scale(1.05);
}

.menu-item-image {
    display: flex;
    align-items: flex-end;
    align-content: flex-end;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(90%);
    transition: filter 0.3s ease;

}

.menu-item:hover .menu-item-image {
    filter: brightness(50%);
}

.menu-item-content {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000000;
    text-align: center;
}

.menu-item-title {
    font-size: 22px;
    font-weight: bold;
}

@media (max-width: 768px) {
    .menu-grid {
        grid-template-columns: 1fr;
    }
}
