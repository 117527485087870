:root {
  --primary-color: #0C77C3;
  --secondary-color: #9747FF;
  --background-color: rgba(255, 255, 255, 0.6);
  --text-color: #242322;
  --font-family: Intervar, -apple-system, SFNSText-Regular, San Francisco, BlinkMacSystemFont, Segoe UI, PT Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
}

body {
  margin: 0;
  overflow-y: auto;
}

.LoginPage-header {
  display: flex;
  justify-content: flex-start;

}

.LoginPage-container {
  font-family: var(--font-family);
  /*background-image: url('../images/background.png');*/
  backdrop-filter: blur(5px);
  background-color: var(--background-color);
  background-blend-mode: lighten;
  background-repeat: repeat;
  /*height: calc(100vh - 190px);*/
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

}

.LoginPage-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: sticky;

  margin-top: 90px;
}
