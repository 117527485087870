.FullReg-container {
    background-image: url('images/background.png');
    background-color: rgba(255, 255, 255, 0.6);
    background-blend-mode: lighten;
    background-repeat: repeat;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
  }
  
  .FullReg-content {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .FullReg-content h1 {
    text-align: center;
    color: #2c5282;
    margin-bottom: 1rem;
  }
  
  .form-field {
    width: 33vh;
  }
  
  .form-field .chakra-input,
  .form-field .chakra-select {
    border-color: #cbd5e0;
    border-radius: 4px;
    padding: 0.75rem;
    width: 100%;
    box-sizing: border-box;
  }
  
  .form-field .chakra-input:focus,
  .form-field .chakra-select:focus {
    border-color: #3182ce;
    box-shadow: 0 0 0 1px #3182ce;
  }
  
  .form-field .chakra-button {
    background-color: #2c5282;
    color: white;
    width: 100%;
    padding: 0.75rem;
    border-radius: 4px;
    margin-top: 1rem;
  }
  
  .form-field .chakra-button:hover {
    background-color: #2b6cb0;
  }
  
  .form-field .chakra-text {
    color: #000000;
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }