:root {
    --border-color-red: rgba(255, 99, 132, 1);
    --border-color-dark-blue: rgba(54, 162, 235, 1);
    --border-color-orange-yellow: rgba(255, 206, 86, 1);
    --border-color-green: rgba(75, 192, 192, 1);
    --border-color-light-purple: rgba(153, 102, 255, 1);
    --border-color-bright-orange: rgba(255, 159, 64, 1);
    --border-color-light-green: rgba(199, 199, 199, 1);
    --border-color-light-blue: rgba(83, 102, 255, 1);
    --border-color-dark-red: rgba(255, 159, 255, 1);

    --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
}

.all-equipment-page {
    display: flex;
    flex-direction: row;
    min-height: calc(100vh - 62px);
}

.filters-panel {
    width: 300px;
    background-color: #f7fafc;
    border-right: 1px solid #e2e8f0;
    /*padding: 5px;*/
}

.equipment-list-container {
    flex: 1;
    padding: 1.5rem;

}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

