.receipt-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
}

.receipt-content {
    display: flex;
    flex-direction: column;
    align-items: center;


}

/* Стиль для контейнера сканера QR-кода */
.scanner-container {
    width: 100%;
    height: 300px;
}

/* Стиль для галереи изображений */
.image-gallery {
    display: flex;
    margin-top: 8px;
}

.image-gallery img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 8px;
}

/* Стиль для формы */
.receipt-form {
    padding: 24px;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
}

/* Стиль для заголовков */
.receipt-header {
    font-size: 24px;
    margin-bottom: 16px;
}

/* Стиль для кнопок */
.button-group button {
    margin-right: 8px;
}

/* Стиль для модального окна */
.comment-modal {
    /* При необходимости добавьте дополнительные стили */
}
