.equipment-list {
    /* Добавьте стили для списка оборудования при необходимости */
}

.equipment-item {
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    overflow: hidden;
    padding: 1rem;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.equipment-item:hover {
    background-color: #f7fafc;
}

.equipment-image {
    height: 110px;
    width: 150px;
    border-radius: 0.375rem;
}

.equipment-details {
    /* Добавьте стили для деталей оборудования при необходимости */
}

.equipment-button {
    /* Добавьте стили для кнопки "Подробнее" при необходимости */
}
