.equipment-details-table {
    overflow-x: auto;
    margin-top: 2rem;
}

.purchase-document-link,
.warranty-card-link {
    color: #3182CE; /* Эквивалент color="blue.500" */
    text-decoration: underline;
}
