:root {
    --border-color-red: rgba(255, 99, 132, 1);
    --border-color-dark-blue: rgba(54, 162, 235, 1);
    --border-color-orange-yellow: rgba(255, 206, 86, 1);
    --border-color-green: rgba(75, 192, 192, 1);
    --border-color-light-purple: rgba(153, 102, 255, 1);
    --border-color-bright-orange: rgba(255, 159, 64, 1);
    --border-color-light-green: rgba(199, 199, 199, 1);
    --border-color-light-blue: rgba(83, 102, 255, 1);
    --border-color-dark-red: rgba(255, 159, 255, 1);

    --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
}

.Main-container {
    font-family: var(--font-family);
    /*background-image: url('../images/background.png');*/
    backdrop-filter: blur(5px);
    background-color: var(--background-color);
    background-blend-mode: lighten;
    background-repeat: repeat;
    /*height: calc(100vh - 190px);*/
    min-height: calc(100vh - 62px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

}

.Main-content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: sticky;

}

.Main-nav-menu {

}

.file-upload-window {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    z-index: -100;
}

.file-upload-window input[type="file"] {
    display: none;
}

.file-upload-window label {
    padding: 8px 21px;
    background-color: #9747FF;
    color: white;
    margin-top: 60%;
    border: none;
    border-radius: 4px;
    cursor: pointer;

}

.file-upload-window label:hover {
    background-color: #8057f8;
    transition: background-color 0.3s ease;
}

.file-download-button {
    margin-top: 24px;
}

.file-download-button a {
    text-decoration: none;
}

.file-download-button button {
    padding: 8px 16px;
    background-color: #1290f8;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.file-download-button button:hover {
    background-color: #3b6392;
    transition: background-color 0.3s ease;
}

.subject-menu {
    z-index: 100 !important;
}

.subject-button {
    background-color: #FFFFFF !important; /* Изменен цвет кнопки */
    color: #000000 !important;
    border: 1px solid #000000 !important;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    margin: 10px;
    position: relative;
    /*top: -460px;*/
    left: 20vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 100 !important;
}

.subject-button .arrow {
    display: inline-block;
    margin-left: 10px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #000;
    transition: transform 0.3s ease;
    z-index: 100 !important;
}

.subject-button.open .arrow {
    transform: rotate(180deg) !important;
    z-index: 100 !important;
}

.subject-button:hover {
    background-color: #e0e0e0 !important;
    z-index: 100 !important;
}

.subject-menu-list {
    z-index: 100 !important;
    position: absolute;
}


