.navbar-search-greeting {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 800px;
}

.navbar-search-form {
    display: flex;
    align-items: center;
    position: relative;
    width: 400px;
    background-color: white;
    border-radius: 4px;
}

.search-input {

    /*background-color: white;*/
    padding-right: 40px;
    border-radius: 4px;
    /*border: 1px solid var(--chakra-colors-gray-200);*/
    transition: border-color 0.2s, box-shadow 0.2s;
}

.search-input:hover {
    border-color: var(--chakra-colors-gray-300);
}

.search-input:focus {
    border-color: var(--chakra-colors-teal-500);
    box-shadow: 0 0 0 1px var(--chakra-colors-teal-500);
}

.search-icon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
}

.search-results {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--chakra-colors-gray-200);
    border-radius: 4px;
    margin-top: 15px;
    padding: 16px;
    background-color: white;
    position: absolute;
    width: 800px;
    z-index: 1000;
    max-height: 600px;
    overflow-y: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-right: auto;
    margin-left: auto;
}

.card-container  {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    width: 100%;
}

.card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.search-card-image {
    width: 180px;
    height: 120px;
    object-fit: cover;
    border-radius: 4px;
}

.category-tag {
    display: flex;
    align-items: center;
}

.category-tag .tag-label {
    margin-left: 4px;
}
