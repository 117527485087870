.equipment-summary {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.equipment-title {
    font-size: 36px;
    font-weight: bold;
    margin: 5px 0 5px 0 ;
}

.equipment-serial-number {
    font-size: 18px;
    color: #4A5568;
    margin: 5px 0 5px 0 ;
}

.equipment-location {
    font-size: 18px;
    color: #4A5568;
    margin: 5px 0 5px 0 ;
}
