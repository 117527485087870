:root {
    --primary-color: #0C77C3;
    --secondary-color: #9747FF;
    --background-color: rgba(255, 255, 255, 0.6);
    --text-color: #242322;
    --font-family: Intervar, -apple-system, SFNSText-Regular, San Francisco, BlinkMacSystemFont, Segoe UI, PT Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.LoginHeading-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 3rem;
}

.LoginHeading-name {
    margin: 0;
    font-size: 1.5rem;
    color: var(--text-color);
}

.LoginHeading-logo {
    width: 160px;
    height: auto;
    margin-right: 2%;
}
