.navbar-header {
    width: 100%;
    height: 62px;
}

.header-top {
    background-color: #2c3e50;
    padding: 8px 0;
}

.header-top__container {
    width: 90%;
    margin: 0 auto;
}

.header-top-menu__common-list {
    list-style: none;
    display: flex;
    justify-content: center;
    gap: 30px;
    margin: 0;
    padding: 0;
}

.header-top-menu__common-item {
}

.header-top-menu__common-link {
    text-decoration: none;
    color: #ecf0f1;
    font-weight: 500;
}

.header-top-menu__common-link:hover {
    color: #3498db;
}

/* Основные стили Navbar */
.navbar-main {
    height: 62px;
    background-color: #ff6411;
    padding: 10px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
}

.navbar-header-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    width: 200px;
}

.navbar-header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    width: 200px;
}

.navbar-logo-image {
    height: 40px;
}


/*.navbar-search-greeting {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    color: #000000;*/
/*    font-size: 14px;*/
/*    position: relative;*/
/*    flex: 1;*/
/*    justify-content: center;*/
/*}*/

/*.navbar-search {*/
/*    background-color: white;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    position: relative;*/
/*    width: 100%;*/
/*    max-width: 400px;*/
/*    height: 36px;*/
/*}*/



.search-icon-button {
    margin-left: 8px;
    background: none;
    border: none;
    cursor: pointer;
}

.navbar-actions {
    display: flex;
    align-items: center;
    gap: 10px;
}

.navbar-menu-actions,  .navbar-user-actions {
    display: flex;
    align-items: center;
    position: relative;
    padding: 5px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(-10px);
    }
}

.dropdown-content {
    position: absolute;
    top: 40px;
    right: 0;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
    padding: 10px;
    z-index: 1000;
    border-radius: 4px;
    min-width: 200px;
    height: auto;
    animation: fadeIn 0.2s ease-in-out;
}

.dropdown-notifications-content {
    position: absolute;
    top: 40px;
    right: 0;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
    padding: 10px;
    z-index: 1000;
    border-radius: 4px;
    min-width: 500px;
    height: auto;
    animation: fadeIn 0.2s ease-in-out;
}

.dropdown-content.closing {
    animation: fadeOut 0.2s ease-in-out;
}

.dropdown-flex-container {
    display: flex;
    flex-direction: row;
}

.notifications-panel {
    flex: 1;
    border-right: 1px solid #ddd;
    padding: 10px;
}

.menu-items-panel {
    flex: 1;
    padding: 10px;
}

.dropdown-item {
    padding: 8px 12px;
    transition: all 0.2s ease;
    border-radius: 4px;
    cursor: pointer;
}

.dropdown-item:hover {
    background-color: rgba(255, 100, 17, 0.1);
    color: #ff6411;
    transform: translateX(5px);
}

.dropdown-item-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333;
    margin-bottom: 10px;
}

.dropdown-icon {
    width: 30px;
    height: 30px;
    margin-right: 8px;
}

.notifications-list {
    list-style: none;
    padding: 0;
    margin: 10px 0 0 0;
    max-width: 500px;
    max-height: 400px;
    overflow-y: auto;
}

.notification-item {
    padding: 8px 12px;
    border-bottom: 1px solid #f0f0f0;
    transition: all 0.2s ease;
    cursor: pointer;
}

.notification-item:hover {
    background-color: rgba(255, 100, 17, 0.1);
    transform: translateX(5px);
}


.notification-item:last-child {
    border-bottom: none;
}

.notification-title {
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 14px;
}

.notification-message {
    font-size: 13px;
    color: #555;
}

.notification-item.unread {
    background-color: #c3edff;
}

.navbar-notifications-actions {
    position: relative;
}

.no-notifications {
    display: block;
    margin-top: 10px;
    color: #888;
}

.navbar-menu-list {
    list-style: none;
    padding: 0;
    margin: 0 0 10px 0;
}

.navbar-menu-item {
    margin-bottom: 5px;
}

.menu-link {
    text-decoration: none;
    color: #333;
    padding: 8px 12px;
    display: block;
    transition: all 0.2s ease;
    border-radius: 4px;
}

.menu-link:hover {
    color: #ff6411;
    background-color: rgba(255, 100, 17, 0.1);
    transform: translateX(5px);
}

/* Стили для кнопок dropdown */
.dropdown-trigger {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    position: relative;
}

.navbar-icon {
    width: 30px;
    height: 30px;
}

.notification-count {
    position: absolute;
    top: -3px;
    right: -3px;
    padding: 1px 4px;
    font-size: 10px;
    background-color: red;
    color: white;
    border-radius: 50%;
}

.user-name {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    color: #333;
}

/* Анимация появления выпадающего меню */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

/* Адаптивные стили */
@media (max-width: 768px) {
    .header-top-menu__common-list {
        flex-direction: column;
        align-items: center;
    }

    .navbar-search-greeting {
        flex-direction: column;

    }

    .navbar-search {
        display: flex;

        max-width: 300px;
    }

    .navbar-icon {
        width: 24px;
        height: 24px;
    }

    .search-input {
        width: 100%;
    }
}