.users-container {
    font-family: var(--font-family);
    /*background-image: url('../images/background.png');*/
    backdrop-filter: blur(5px);
    background-color: var(--background-color);
    background-blend-mode: lighten;
    background-repeat: repeat;
    /*height: calc(100vh - 190px);*/
    min-height: calc(100vh - 62px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

}

.users-content {
    padding: 1rem;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: sticky;
}

.users-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-weight: 600;
}

.error-message {
    color: #E53E3E;
}

.users-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.user-card {
    display: flex;
    min-width: 700px;
    padding: 1rem;
    border: 1px solid #E2E8F0;
    border-radius: 0.375rem;
    justify-content: space-between;
    align-items: center;
}

.user-info {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.user-avatar {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
}

.user-details {
    display: flex;
    flex-direction: column;
}

.user-name {
    font-weight: 700;
}

.user-activity {
    font-size: 0.875rem;
    cursor: help;
}

.user-actions {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.status-badge {
    padding: 0.125rem 0.75rem;
    border-radius: 0.375rem;
    font-weight: 500;
    font-size: 0.875rem;
}

.status-badge.online {
    background-color: #C6F6D5;
    color: #276749;
}

.status-badge.offline {
    background-color: #EDF2F7;
    color: #2D3748;
}

.details-button {
    background-color: #3182CE;
    color: #FFFFFF;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    font-weight: 600;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
}

.details-button:hover {
    background-color: #2B6CB0;
}

.details-button:active {
    background-color: #2C5282;
}