.catalog-list-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2.5rem;
    padding: 2rem;
}

@media (min-width: 768px) {
    .catalog-list-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1024px) {
    .catalog-list-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

.catalog-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #FFFFFF;
    border: 1px solid #E2E8F0;
    border-radius: 0.5rem;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.2s;
    padding: 10px;
    width: 270px;
}

.catalog-item:hover {
    transform: scale(1.02);
}

.catalog-image {
    height: 170px;
    width: auto;
    /*object-fit: cover;*/
    margin-inline: auto;
}

.catalog-content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    padding: 10px;
    flex-grow: 1;
}

.catalog-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px;
}

.catalog-description {
    color: #A0AEC0;
    margin-bottom: 8px;
}

.catalog-info {
    display: flex;
    font-size: 12px;
    color: #CBD5E0;
}
