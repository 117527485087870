.equipment-image-container {
    flex: 1;
    position: relative;
}

.equipment-large-image {
    width: 100%;
    height: auto;
    border-radius: 0.375rem; /* borderRadius="md" */
    transition: transform 0.3s ease;
}

.equipment-large-image:hover {
    transform: scale(1.05);
}

.equipment-details-table {
    overflow-x: auto;
    margin-top: 2rem;
}

.purchase-document-link,
.warranty-card-link {
    color: #3182CE; /* Эквивалент color="blue.500" */
    text-decoration: underline;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}


/* Миниатюры изображений */
.equipment-image-container .equipment-image-thumb {
    border: 2px solid teal;
}
