:root {
    --border-color-red: rgba(255, 99, 132, 1);
    --border-color-dark-blue: rgba(54, 162, 235, 1);
    --border-color-orange-yellow: rgba(255, 206, 86, 1);
    --border-color-green: rgba(75, 192, 192, 1);
    --border-color-light-purple: rgba(153, 102, 255, 1);
    --border-color-bright-orange: rgba(255, 159, 64, 1);
    --border-color-light-green: rgba(199, 199, 199, 1);
    --border-color-light-blue: rgba(83, 102, 255, 1);
    --border-color-dark-red: rgba(255, 159, 255, 1);

    --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
}

.CatalogPage-container {
    font-family: var(--font-family);
    /*background-image: url('../images/background.png');*/
    backdrop-filter: blur(5px);
    background-color: var(--background-color);
    background-blend-mode: lighten;
    background-repeat: repeat;
    /*height: calc(100vh - 190px);*/
    min-height: calc(100vh - 62px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.CatalogPage-content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: sticky;

}