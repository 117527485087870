.profile-settings-container {
    padding: 2rem;
    max-width: 600px;
    margin: 0 auto;
}

.profile-heading {
    margin-bottom: 2rem;
    text-align: center;
}

.profile-form {
    width: 100%;
    /*background: white;*/
    /*padding: 2rem;*/
    /*border-radius: 8px;*/
    /*box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);*/
}

.update-button {
    width: 100%;
    margin-top: 1rem !important;
}

.change-password-button {
    width: 100%;
}

.logout-button {
    width: 100%;
}

.password-modal {
    padding: 1rem;
}