.history-page-container {
    font-family: var(--font-family);
    /*background-image: url('../images/background.png');*/
    backdrop-filter: blur(5px);
    background-color: var(--background-color);
    background-blend-mode: lighten;
    background-repeat: repeat;
    /*height: calc(100vh - 190px);*/
    min-height: calc(100vh - 62px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

}

.history-page-content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    margin: 20px;

}
