:root {
    --primary-color: #0C77C3;
    --secondary-color: #9747FF;
    --background-color: rgba(255, 255, 255, 0.6);
    --text-color: #242322;
    --font-family: Intervar, -apple-system, SFNSText-Regular, San Francisco, BlinkMacSystemFont, Segoe UI, PT Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
}


.Login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: var(--font-family);
    backdrop-filter: blur(5px);
    background-blend-mode: lighten;
    background-repeat: repeat;

}

.Login-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Login-form {
    display: flex;
    flex-direction: column;
    /*gap: 1rem;*/
    /*width: 100%;*/
}

.Login-login-modal-wrapper {
    background: rgba(255, 255, 255, 0.95);
    padding: 40px;
    border-radius: 30px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    /*max-width: 400px;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    /*width: 25vh !important;*/
    /*height: 40vh !important;*/

}

.Login-button-register {
    justify-content: center;
    border-radius: 8px !important;
    border: 1px solid #0C77C3 !important;
    background: rgba(12, 119, 195, 0.05) !important;
    color: #0C77C3 !important;
    text-align: center;
    font-family: "Segoe UI";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
    padding: 10px;
    cursor: pointer;
    width: 20vh;
    margin-top: 1vh !important;
    height: 2.8vh !important;
    /*margin-top: 1vh !important;*/
    position: sticky;
}

.Login-button-login {
    background-color: #ff6411 !important;
    justify-content: center;
    background: var(--secondary-color);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.05), 0px 1px 1px 0px rgba(0, 0, 0, 0.10) !important;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    width: 20vh !important;
    margin-top: 2vh !important;
    position: sticky;
}

.Login-button-login:hover {
    background-color: #ff7c26 !important;
    transition: background-color 0.3s ease !important;
}

.Login-button-register:hover {
    background-color: rgba(12, 119, 195, 0.1);
    transition: background-color 0.3s ease;
}

.Login-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    /*gap: 1rem;*/
}

.Login-button-group {
    position: sticky;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    /*gap: 1rem;*/
}
