.AlphaVersionModal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90vw;
    max-width: 500px; /* Добавляем максимальную ширину для предотвращения чрезмерного расширения */
    height: auto;
    padding: 20px; /* Добавляем внутренние отступы для улучшения читаемости */
    border-radius: 8px; /* Закругление углов для эстетики */
}

@media (max-width: 768px) {
    .AlphaVersionModal-content {
        width: 90vw;
        max-width: 90vw; /* Устанавливаем максимальную ширину на 90% от ширины экрана */
        padding: 15px;
    }

    .AlphaVersionModal-content .modal-header,
    .AlphaVersionModal-content .modal-body,
    .AlphaVersionModal-content .modal-footer {
        padding: 10px;
    }

    .AlphaVersionModal-content .modal-header {
        font-size: 1.5rem; /* Уменьшаем размер шрифта заголовка */
    }

    .AlphaVersionModal-content .modal-body {
        font-size: 1rem; /* Уменьшаем размер шрифта тела модального окна */
    }

    .AlphaVersionModal-content .modal-footer button {
        width: 100%; /* Кнопка занимает всю ширину футера */
        padding: 10px 0;
    }
}
